import React, { useState, useEffect } from "react";
import futureFieldsIcon from "../../../../../assets/futureFieldsIcon.png";
import "./InterestedFields.css";

const InterestedFieldsCard = ({ interestedFields }) => {
  return (
    <div className="card">
      <h2 className="card-title">Opportunities type Interested</h2>
      <div className="flex">
        <img src={futureFieldsIcon} alt="" className="card-image" />
        <div className="flex flex-column">
          <p className="card-details">
            The interested fields for future opportunities.
          </p>
          <div
            className="fieldsWrapper"
            style={{ width: "100%", display: "flex" }}
          >
            {interestedFields &&
              interestedFields.map((field) => {
                return <div className="fieldWrapper">{field}</div>;
              })}
          </div>
        </div>
      </div>
      {/* <hr style={{ margin: "4rem 0", opacity: "60%" }} /> */}
    </div>
  );
};

export default InterestedFieldsCard;
