import React from "react";
import MissionImg from "../../../assets/MissionImg.png";
import "./index.css";

const Mission = () => {
  return (
    <div className="missionWrapper">
      <div className="mvImgWrapper">
        <img className="mvImg" src={MissionImg} alt="/" />
      </div>
      <div className="homeHeading1">
        Our{" "}
        <span className="homeHeadingSpan" style={{ color: "#DFA114" }}>
          Mission
        </span>
      </div>
      <div className="missionInfo">
        OpGuru is on a mission to empower students in underserved communities to
        confidently navigate their educational pathways. We are dedicated to
        mentoring and supporting at least 5,000 students each year, providing
        them with the resources and guidance to make informed career decisions.
        Through personalized attention and innovative solutions, we strive to
        help every student pursue a path that aligns with their unique talents
        and aspirations, fostering a future where all individuals can thrive and
        contribute meaningfully to society.
      </div>
    </div>
  );
};

export default Mission;
