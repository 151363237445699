import React from "react";
import CollegeIcon from "../../../../../../assets/collegeIconProfile.png";
import SchoolIcon from "../../../../../../assets/schoolIcon.png";
import SchoolBoard from "../../../../../options/schoolBoard";

const EducationLevel = ({
  institution,
  schoolBoard,
  degree,
  year,
  gpa,
  currentClass,
  percentage,
  type,
}) => {
  if (year === "1") year = "1st year";
  if (year === "2") year = "2nd year";
  if (year === "3") year = "3rd year";
  if (year === "4") year = "4rth year";
  console.log("year is ", year);
  return (
    <div className="flex">
      {type === "college" ? (
        <img src={CollegeIcon} className="card-image" />
      ) : (
        <img src={SchoolIcon} className="card-image" />
      )}

      <div className="flex flex-column">
        <h2 className="card-subtitle">{institution}</h2>
        <p
          style={{
            fontSize: "1.5rem",
            fontWeight: "bold",
            marginBottom: "1rem",
          }}
        >
          {schoolBoard && <span className="card-details">{schoolBoard}</span>}
        </p>
        {currentClass && (
          <p className="card-details">
            Class {currentClass} {percentage ? `- ${percentage} %` : ""}
          </p>
        )}
        {year && (
          <p className="card-details">
            {degree} - {year}
          </p>
        )}
        {gpa && (
          <p className="card-details" style={{ paddingTop: "0.5rem" }}>
            Current CGPA : {gpa}
          </p>
        )}
      </div>
    </div>
  );
};

export default EducationLevel;
