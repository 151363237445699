import React, { useState, useEffect } from "react";
import EducationCard from "./EducationCard";
import InterestedFieldsCard from "./InterestedFields/InterestedFields";
import OtherDetails from "./OtherDetails/index.jsx";
import CollegeList from "../../../userForms/FormsData/Colleges.js";
import FutureFields from "../../../userForms/FormsData/FutureFields.js";
import Specializations from "../../../options/phdDegree.js";
import axios from "../../../../api/axios";

const PhdProfile = ({ userID, category }) => {
  const GET_PHD_ROUTE = "/api/getPhdUser";
  const [collegeName, setCollegeName] = useState(null);
  const [collegeYear, setCollegeYear] = useState(null);
  const [currGPA, setCurrGPA] = useState(null);
  const [specialization, setSpecialization] = useState(null);
  const [interestedFields, setInterestedFields] = useState(null);
  const [interestedRegions, setInterestedRegions] = useState(null);

  useEffect(() => {
    const getData = async () => {
      const userDetails = await axios.get(GET_PHD_ROUTE, {
        params: {
          userID: userID,
        },
      });
      console.log("User details is ", userDetails.data);
      setCollegeName(
        CollegeList[JSON.parse(userDetails?.data?.collegename) - 1].label
      );
      setCollegeYear(userDetails?.data?.collegeyear);
      setSpecialization(
        Specializations[JSON.parse(userDetails?.data?.specialization) - 1].label
      );
      setCurrGPA(userDetails?.data?.gpa);
      setInterestedRegions(JSON.parse(userDetails?.data?.interestedregions));
      let arr1 = [];
      let FutureFieldsArr = JSON.parse(
        userDetails?.data?.futureOpportunityTypes
      );
      console.log("Future fields arr is ", FutureFieldsArr);
      for (let i = 0; i < FutureFieldsArr.length; i++) {
        const Index = parseInt(FutureFieldsArr[i], 10) - 1;
        if (FutureFields[Index]) {
          arr1.push(FutureFields[Index].label);
        }
      }
      setInterestedFields(arr1);
    };
    getData();
  }, [userID, category]);

  return (
    <div>
      <EducationCard
        collegename={collegeName}
        collegeyear={collegeYear}
        collegedegree="Phd"
        specialization={specialization}
        gpa={currGPA}
      ></EducationCard>
      <InterestedFieldsCard
        interestedFields={interestedFields}
      ></InterestedFieldsCard>
      <OtherDetails interestedregions={interestedRegions}></OtherDetails>
    </div>
  );
};

export default PhdProfile;
