import React, { useState, useEffect } from "react";
import EducationCard from "./EducationCard";
import InterestedFieldsCard from "./InterestedFields/InterestedFields";
import Opportunities from "../../../options/OpportunitiesTypes8th.js";
import axios from "../../../../api/axios";

const Below8thProfile = ({ userID, category }) => {
  const GET_BELOW8TH_ROUTE = "/api/getBelow8thUser";
  const [schoolName, setSchoolName] = useState(null);
  const [schoolBoard, setSchoolBoard] = useState(null);
  const [currentClass, setCurrentClass] = useState(null);
  const [interestedFields, setInterestedFields] = useState(null);
  useEffect(() => {
    const getData = async () => {
      const userDetails = await axios.get(GET_BELOW8TH_ROUTE, {
        params: {
          userID: userID,
        },
      });
      console.log("User details are ", userDetails.data);
      setSchoolName(userDetails?.data?.schoolname);
      setSchoolBoard(userDetails?.data?.schoolBoard);
      setCurrentClass(userDetails?.data?.currentclass);
      let arr = [];
      let FutureoppsTypes = JSON.parse(userDetails?.data?.opportunities);
      console.log("Future opps is ", FutureoppsTypes);
      for (let i = 0; i < FutureoppsTypes.length; i++) {
        const Index = parseInt(FutureoppsTypes[i], 10) - 1;
        if (Opportunities[Index]) {
          arr.push(Opportunities[Index].label);
        }
      }
      setInterestedFields(arr);
    };
    getData();
  }, [userID, category]);
  return (
    <div>
      <EducationCard
        schoolName={schoolName}
        schoolBoard={schoolBoard}
        currentClass={currentClass}
      ></EducationCard>
      <InterestedFieldsCard
        interestedFields={interestedFields}
      ></InterestedFieldsCard>
    </div>
  );
};

export default Below8thProfile;
