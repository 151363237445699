import React, { useState, useEffect } from "react";
import EducationCard from "./EducationCard";
import InterestedFieldsCard from "./InterestedFields/InterestedFields";
import OtherDetails from "./OtherDetails/index.jsx";
import SchoolBoard from "../../../options/schoolBoard.js";
import CollegeList from "../../../userForms/FormsData/Colleges.js";
import FutureFields from "../../../userForms/FormsData/FutureFields.js";
import DegreeList from "../../../options/MasterDegree.js";
import axios from "../../../../api/axios";

const MastersProfile = ({ userID, category }) => {
  const GET_MASTERS_ROUTE = "/api/getMastersUser";
  const [collegeName, setCollegeName] = useState(null);
  const [collegeYear, setCollegeYear] = useState(null);
  const [collegeDegree, setCollegeDegree] = useState(null);
  const [futureDegree, setFutureDegree] = useState(null);
  const [currGPA, setCurrGPA] = useState(null);
  const [twelthSchoolName, setTwelthSchoolName] = useState(null);
  const [tenthSchoolName, setTenthSchoolName] = useState(null);
  const [tenthBoard, setTenthSchoolBoard] = useState(null);
  const [twelthBoard, setTwelthSchoolBoard] = useState(null);
  const [tenthPercentage, setTenthPercentage] = useState(null);
  const [twelthPercentage, setTwelthPercentage] = useState(null);
  const [interestedFields, setInterestedFields] = useState(null);
  const [interestedRegions, setInterestedRegions] = useState(null);

  useEffect(() => {
    const getData = async () => {
      const userDetails = await axios.get(GET_MASTERS_ROUTE, {
        params: {
          userID: userID,
        },
      });
      console.log("User details is ", userDetails.data);
      setCollegeName(
        CollegeList[JSON.parse(userDetails?.data?.collegename) - 1].label
      );
      setCollegeYear(userDetails?.data?.collegeyear);
      setCollegeDegree(
        DegreeList[JSON.parse(userDetails?.data.currentdegree) - 1].label
      );
      setFutureDegree(
        DegreeList[JSON.parse(userDetails?.data.futuredegree) - 1].label
      );
      setCurrGPA(userDetails?.data?.gpa);
      setTenthSchoolName(userDetails?.data?.tenthschoolname);
      setTenthSchoolBoard(
        SchoolBoard[JSON.parse(userDetails?.data?.tenthBoard) - 1].label
      );
      setTenthPercentage(userDetails?.data?.tenthPercentage);
      setTwelthSchoolName(userDetails?.data?.twelthschoolname);
      setTwelthSchoolBoard(
        SchoolBoard[JSON.parse(userDetails?.data?.twelthBoard) - 1].label
      );
      setTwelthPercentage(userDetails?.data?.twelthPercentage);
      setInterestedRegions(JSON.parse(userDetails?.data?.interestedregions));
      let arr1 = [];
      let FutureFieldsArr = JSON.parse(
        userDetails?.data?.futureOpportunityTypes
      );
      console.log("Future fields arr is ", FutureFieldsArr);
      for (let i = 0; i < FutureFieldsArr.length; i++) {
        const Index = parseInt(FutureFieldsArr[i], 10) - 1;
        if (FutureFields[Index]) {
          arr1.push(FutureFields[Index].label);
        }
      }
      setInterestedFields(arr1);
    };
    getData();
  }, [userID, category]);

  return (
    <div>
      <EducationCard
        collegename={collegeName}
        collegeyear={collegeYear}
        collegedegree={collegeDegree}
        gpa={currGPA}
        tenthschoolname={tenthSchoolName}
        tenthboard={tenthBoard}
        twelthschoolname={twelthSchoolName}
        twelthboard={twelthBoard}
        tenthpercentage={tenthPercentage}
        twelthpercentage={twelthPercentage}
      ></EducationCard>
      <InterestedFieldsCard
        interestedFields={interestedFields}
        futuredegree={futureDegree}
      ></InterestedFieldsCard>
      <OtherDetails interestedregions={interestedRegions}></OtherDetails>
    </div>
  );
};

export default MastersProfile;
