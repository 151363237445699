import "./index.css";
import React, { useState, useEffect } from "react";
import Header from "../../Headers/HeaderForms";
import Footer from "../../Footer/Footer.js";
import Dropdown from "../../Usables/Dropdown/Dropdown.js";
import { useNavigate } from "react-router-dom";
import BachelorExams from "../Bachelors/BachelorsExam";
import axios from "../../../api/axios";
import RegionsModal from "../../Usables/RegionModal";
import { v4 as uuid } from "uuid";
import useAuth from "../../../hooks/useAuth.js";
import useAxiosPrivate from "../../../hooks/useAxiosPrivate";
import SchoolBoard from "../../options/schoolBoard.js";
import Budget from "../../options/Budget.js";
import Aggregate from "../../options/Aggregate.js";
import Best from "../../options/Best.js";
import Streams from "../../options/Streams.js";
import Curr from "../../options/CurrentClass1011.js";
import FutureDegree from "../../options/FutureDegree.js";
import OlympiadsTypes11_12th from "../../options/OlympiadTypes11_12th.js";
import Olympiads from "../../options/Olympiads.js";
import MultiSelectDropdown from "../../Usables/MultiSelectDropdown/index.js";
import FloatInputField from "../../Usables/FloatInputField/index.js";

const Class11th12th = () => {
  const { auth } = useAuth();
  const SAVE_LOGIN_ROUTE = "/api/saveLoginRoute";
  const SAVE_CATEGORY = "/api/saveCategory";

  const axiosPrivate = useAxiosPrivate();
  const navigate = useNavigate();

  const handleBack = async () => {
    const response = await axiosPrivate.post(
      SAVE_LOGIN_ROUTE,
      {
        userID: auth.userID,
        loginroute: "selectClass",
      },
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${auth.accessToken}`,
        },
        withCredentials: true,
      }
    );
    console.log("response from saving route api is ", response);
    // If everything is successful, then only we will navigate
    navigate("/selectClass");
  };

  const [streamLabel, setStreamLabel] = useState(null);
  const [streamValue, setStreamValue] = useState(null);
  const [currentclass, setClass] = useState(null);
  const [currentBoardLabel, setCurrentBoardLabel] = useState(null);
  const [currentBoardValue, setCurrentBoardValue] = useState(null);
  const [tenthBoardLabel, setTenthBoardLabel] = useState(null);
  const [tenthBoardValue, setTenthBoardValue] = useState(null);
  const [tenthPercentage, setTenthPercentage] = useState(null);
  const [tenthSchoolName, setTenthSchoolName] = useState("");
  const [schoolName, setSchoolName] = useState("");
  const [bestOfFive, setBest] = useState(0);
  const [aggregate, setAggregate] = useState(0);
  const [olympiads, setOlympiads] = useState([]);
  const [olympiadsInterested, setOlympiadsInterested] = useState([]);
  const [futureolympiadtypes, setFutureOlympiadTypes] = useState([]);
  const [futurefields, setFutureFields] = useState([]);
  const [futureDegreeLabel, setFutureDegreeLabel] = useState(null);
  const [futureDegreeValue, setFutureDegreeValue] = useState(null);
  const [budgetLabel, setBudgetLabel] = useState(null);
  const [budgetValue, setBudgetValue] = useState(null);
  const [err, setErr] = useState(null);

  // GET OPTIONS FROM DATABASE

  const newExam = [
    {
      type: "text",
      id: 1,
      name: "",
      date: "",
      percentage: "",
      criteria: "100 %",
    },
  ];

  const [exams, setExams] = useState(newExam);

  const unique_id = uuid();
  const newRegion = [
    {
      id: unique_id,
      regionName: "Select Region",
      subRegions: [],
      check: false,
    },
  ];

  const [regions, setRegions] = useState(newRegion);
  console.log("regions is ", regions);

  const handleStream = (e) => {
    let ind = parseInt(e.value);
    console.log(Streams[ind - 1].label);
    setStreamLabel(Streams[ind - 1].label);
    setStreamValue(e.value);
  };

  const handleClass = (e) => {
    setClass(e.value);
  };

  const handleCurrentBoard = (e) => {
    let ind = parseInt(e.value);
    console.log(SchoolBoard[ind - 1].label);
    setCurrentBoardLabel(SchoolBoard[ind - 1].label);
    setCurrentBoardValue(e.value);
  };

  const handleTenthBoard = (e) => {
    let ind = parseInt(e.value);
    console.log(SchoolBoard[ind - 1].label);
    setTenthBoardLabel(SchoolBoard[ind - 1].label);
    setTenthBoardValue(e.value);
  };

  const handleTenthPercentage = (event) => {
    const value = event.target.value;
    if (value !== "" && isNaN(value)) {
      setErr("* Please enter a valid number between 0 and 100");
    } else if (value < 0 || value > 100) {
      setErr("* Please enter a number between 0 and 100");
    } else {
      setErr(null); // Clear the error if the value is valid
    }
    setTenthPercentage(value);
  };

  const handleOlympiads = (options) => {
    setOlympiads(options);
  };

  const handleOlympiadsInterested = (options) => {
    setOlympiadsInterested(options);
  };

  const handleFutureOlympiadTypes = (options) => {
    setFutureOlympiadTypes(options);
  };

  const handleBudget = (e) => {
    let ind = parseInt(e.value);
    console.log(Budget[ind - 1].label);
    setBudgetLabel(Budget[ind - 1].label);
    setBudgetValue(e.value);
  };
  const handleAggregate = (selectedOption) => {
    setAggregate(selectedOption.value);
  };

  const handleFutureDegree = (e) => {
    let ind = parseInt(e.value);
    console.log(FutureDegree[ind - 1].label);
    setFutureDegreeLabel(FutureDegree[ind - 1].label);
    setFutureDegreeValue(e.value);
  };

  const handleBestOfFive = (e) => {
    setBest(e.value);
  };

  const handleTenthSchoolName = (event) => {
    const value = event.target.value;
    setTenthSchoolName(value);
  };

  const handleSchoolName = (event) => {
    const value = event.target.value;
    setSchoolName(value);
  };

  const getLabelsAndValues = (arr) => {
    let labels = [];
    let values = [];
    for (let i = 0; i < arr.length; i++) {
      labels.push(arr[i].label);
      values.push(arr[i].value);
    }
    return { labels, values };
  };

  const { labels: OlympiadLabels, values: OlympiadValues } =
    getLabelsAndValues(olympiads);

  const {
    labels: olympiadsInterestedLabels,
    values: olympiadsInterestedValues,
  } = getLabelsAndValues(olympiadsInterested);

  const {
    labels: futureOlympiadTypesValues,
    values: futureOlympiadTypesLabels,
  } = getLabelsAndValues(futureolympiadtypes);

  const data = {
    userID: auth.userID,
    currentclass: currentclass,
    currentstream: streamValue,
    schoolBoard: currentBoardValue,
    class10thboard: tenthBoardValue,
    futuredegree: futureDegreeValue,
    class10percent: tenthPercentage,
    futurefields: JSON.stringify(futurefields),
    tenthschoolname: tenthSchoolName,
    schoolname: schoolName,
    budget: budgetValue,
    aggregatepercent: aggregate,
    BestOfFive: bestOfFive,
    exams: JSON.stringify(exams),
    interestedregions: JSON.stringify(regions),
    olympiadparticipated: JSON.stringify(OlympiadValues),
    olympiadinterested: JSON.stringify(olympiadsInterestedValues),
    futureolympiadtypes: JSON.stringify(futureOlympiadTypesValues),
  };

  useEffect(() => {
    console.log("data is ", data);
  }, [data]);

  const handleClick = async () => {
    try {
      const userDetail = await axios.get("/api/getuserdata", {
        params: {
          userID: auth.userID,
        },
      });
      const userData = userDetail.data;
      console.log("userDetail is ", userData);
      const controller = new AbortController();
      const response1 = await axiosPrivate.post("/api/eleven12thuser", data, {
        signal: controller.signal,
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${auth.accessToken}`,
        },
        withCredentials: true,
      });

      if (response1.ok) {
        const responseData = await response1.json();
        console.log("11th-12th details saved successfully:", responseData);
      } else {
        console.log("The current data state is :", response1.statusText);
      }
      await axiosPrivate.post(
        SAVE_CATEGORY,
        {
          userID: auth.userID,
          category: "11-12th",
        },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${auth.accessToken}`,
          },
          withCredentials: true,
        }
      );
      const response2 = await axiosPrivate.post(
        "/api/saveLoginRoute",
        {
          userID: auth.userID,
          loginroute: "profile",
        },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${auth.accessToken}`,
          },
          withCredentials: true,
        }
      );

      for (let i = 0; i < exams.length; i++) {
        const examsdata = {
          userID: JSON.stringify(auth.userID),
          examname: JSON.stringify(exams[i].name),
          score: JSON.stringify(exams[i].percentage),
          date: JSON.stringify(exams[i].date),
        };
        const response3 = await axiosPrivate.post(
          "/api/submitExam",
          examsdata,
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${auth.accessToken}`,
            },
            withCredentials: true,
          }
        );
        if (response3.ok) {
          const responseData = await response3.json();
          console.log("exam saved successfully:", responseData);
        } else {
          console.log("The current state is :", response3.statusText);
        }
      }
      fetchOpportunities(userData);
      navigate("/profile");
      controller.abort();
    } catch (err) {
      console.log("error submitting details", err);
    }
  };

  const fetchOpportunities = async (userData) => {
    try {
      const gender = userData.gender;
      const country = userData.country;
      const response = await axios.post(
        "https://opgururag.azurewebsites.net/search",
        {
          query: `Find ten different opportunities that best match the profile of a user who is currently a high school student in class ${currentclass}. The student is a ${gender} and is from ${country}. The student has taken their current stream as ${streamLabel} and their fields of interest for future opportunities and olympiads include ${JSON.stringify(
            futureOlympiadTypesLabels
          )}. They scored ${tenthPercentage}% in 10th grade from the ${tenthBoardLabel} board. The student is interested in opportunities in the following regions: ${JSON.stringify(
            regions
          )}. In the past, the student has participated in the following Olympiads: ${JSON.stringify(
            OlympiadLabels
          )}, and they have appeared for the following proficiency exams with score : ${JSON.stringify(
            exams
          )}. Please find opportunities including scholarships, fellowships, competetions, olympiads and conferences. Provide maximum information for each opportunity, including the title, application deadline, eligibility criteria (such as age limits, GPA requirements, current degree pursuing, eligible regions, citizenship), location/country, description, and type of opportunity. Return the results in a structured format for easy parsing and integration.`,
          userID: auth.userID,
        }
      );
      console.log("response data after opgururag is =>", response.data);
      const finalData = response.data;
      console.log("finalData is ", finalData);
    } catch (error) {
      console.error("Error fetching Opportunities:", error);
    }
  };

  return (
    <div className="userFormWrapper">
      <Header></Header>
      <div className="studentFormWrapper">
        <div className="formHeading">
          Registration Form for class 11th - 12th
        </div>
        <div className="formMessage">
          Enter all Your Registration details mention below
        </div>
        <form style={{ width: "100%" }}>
          <div className="inputForm">
            <Dropdown
              name="streams"
              value={streamLabel}
              options={Streams}
              placeHolder="Current Stream"
              onChange={handleStream}
            ></Dropdown>
            <br />
            <Dropdown
              name="class"
              value={currentclass}
              options={Curr}
              placeHolder="Current Class"
              onChange={handleClass}
            ></Dropdown>
            <br />
            <Dropdown
              name="schoolboard"
              value={currentBoardLabel}
              options={SchoolBoard}
              placeHolder="School Board"
              onChange={handleCurrentBoard}
            ></Dropdown>
            <br />
            <Dropdown
              name="TenthBoard"
              value={tenthBoardLabel}
              options={SchoolBoard}
              placeHolder="Tenth Board"
              onChange={handleTenthBoard}
            ></Dropdown>
            <FloatInputField
              name="TenthPercentage"
              placeHolder="Tenth Percentage"
              handleInput={handleTenthPercentage}
              err={err}
              inputVal={tenthPercentage}
            ></FloatInputField>
            <FloatInputField
              name="TenthSchoolName"
              placeHolder="10th School Name"
              handleInput={handleTenthSchoolName}
              inputVal={tenthSchoolName}
            ></FloatInputField>
            <FloatInputField
              name="SchoolName"
              placeHolder="Current School Name"
              handleInput={handleSchoolName}
              inputVal={schoolName}
            ></FloatInputField>
            <Dropdown
              name="BestOfFive"
              value={Best}
              options={Best}
              placeHolder="Best of 5 marks"
              onChange={handleBestOfFive}
            ></Dropdown>
            <br />
            <Dropdown
              name="Aggregate"
              value={Aggregate}
              options={Aggregate}
              placeHolder="Aggregate Percentage"
              onChange={handleAggregate}
            ></Dropdown>
            <br />
            <BachelorExams exams={exams} setExams={setExams}></BachelorExams>
            <br />
            <MultiSelectDropdown
              options={Olympiads}
              onSelect={handleOlympiads}
              placeholderDropdown="Olympiads Participated"
              placeholderInput="Search for olympiads"
              showSearchBar={true}
              showSelectAll={false}
            ></MultiSelectDropdown>
            <br />
            <MultiSelectDropdown
              options={Olympiads}
              onSelect={handleOlympiadsInterested}
              placeholderDropdown="Olympiads Interested in future"
              placeholderInput="Search for olympiads"
              showSearchBar={true}
              showSelectAll={false}
            ></MultiSelectDropdown>
            <br />
            <Dropdown
              name="futureDegree"
              value={futureDegreeLabel}
              options={FutureDegree}
              placeHolder="Future Degree"
              onChange={handleFutureDegree}
            ></Dropdown>
            <br />
            <Dropdown
              name="budget"
              value={budgetLabel}
              options={Budget}
              placeHolder="Budget in INR"
              onChange={handleBudget}
            ></Dropdown>
            <br />
            <MultiSelectDropdown
              options={OlympiadsTypes11_12th}
              onSelect={handleFutureOlympiadTypes}
              placeholderDropdown="Olympiads type Interested in future"
              placeholderInput="Search for your category"
              showSearchBar={true}
              showSelectAll={false}
            ></MultiSelectDropdown>
          </div>
        </form>
        <div style={{ width: "100%" }}>
          <RegionsModal
            regions={regions}
            setRegions={setRegions}
          ></RegionsModal>
        </div>
        <div className="personalDetailsBtns">
          <button className="yellowBtn Btn1" onClick={handleBack}>
            Back
          </button>
          <button className="yellowBtn Btn1" onClick={handleClick}>
            Next
          </button>
        </div>
      </div>
      <Footer></Footer>
    </div>
  );
};

export default Class11th12th;
