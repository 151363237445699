import React, { useState, useEffect } from "react";
import EducationLevel from "./EducationLevel";

const EducationCard = ({ schoolName, schoolBoard, currentClass }) => {
  if (currentClass === "9") currentClass = "9th";
  else if (currentClass === "10") currentClass = "10th";
  return (
    <div className="card">
      <h1 className="card-title">Education</h1>
      {schoolName && (
        <div>
          <EducationLevel
            institution={schoolName}
            schoolBoard={schoolBoard}
            currentClass={currentClass}
          ></EducationLevel>
        </div>
      )}
    </div>
  );
};

export default EducationCard;
