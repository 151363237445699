import React, { useState, useEffect } from "react";
import EducationLevel from "./EducationLevel";

const EducationCard = ({
  collegename,
  collegeyear,
  collegedegree,
  gpa,
  tenthschoolname,
  tenthboard,
  twelthschoolname,
  twelthboard,
  tenthpercentage,
  twelthpercentage,
}) => {
  console.log("college degree is ", collegedegree);
  return (
    <div className="card">
      <h1 className="card-title">Education</h1>
      {collegename && (
        <div>
          <EducationLevel
            institution={collegename}
            degree={collegedegree}
            type="college"
            year={collegeyear}
            gpa={gpa}
          ></EducationLevel>
        </div>
      )}
      {twelthschoolname && (
        <div>
          <hr style={{ margin: "4rem 0", opacity: "60%" }} />
          <EducationLevel
            institution={twelthschoolname}
            schoolBoard={twelthboard}
            currentClass="12th"
            percentage={twelthpercentage}
          ></EducationLevel>
        </div>
      )}
      {tenthschoolname && (
        <div>
          <hr style={{ margin: "4rem 0", opacity: "60%" }} />
          <EducationLevel
            institution={tenthschoolname}
            schoolBoard={tenthboard}
            currentClass="10th"
            percentage={tenthpercentage}
          ></EducationLevel>
        </div>
      )}
    </div>
  );
};

export default EducationCard;
