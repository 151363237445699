import React from "react";
import UserDetails from "../../../../../assets/userDetails.png";

const OtherDetails = ({ interestedregions }) => {
  return (
    <div className="card">
      <h2 className="card-title">Other Details</h2>
      <div className="flex">
        <div
          className="imgWrapper"
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <img src={UserDetails} alt="" className="card-image" />
        </div>
        <div className="flex flex-column">
          {interestedregions && (
            <div>
              <p className="card-details">Interested Regions :</p>
              <div
                className="fieldsWrapper"
                style={{ width: "100%", display: "flex" }}
              >
                {interestedregions &&
                  interestedregions.map((region) => {
                    return (
                      <div>
                        <div className="fieldWrapper">{region.continent}</div>
                        {/* {JSON.parse(region.countries).map((country) => {
                          return <div className="fieldWrapper">{country}</div>;
                        })} */}
                      </div>
                    );
                  })}
              </div>
            </div>
          )}
        </div>
      </div>
      {/* <hr style={{ margin: "4rem 0", opacity: "60%" }} /> */}
    </div>
  );
};

export default OtherDetails;
