import React from "react";
import UserDetails from "../../../../../assets/userDetails.png";

const OtherDetails = ({ olympiads, subjects }) => {
  return (
    <div className="card">
      <h2 className="card-title">Other Details</h2>
      <div className="flex">
        <div
          className="imgWrapper"
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <img src={UserDetails} alt="" className="card-image" />
        </div>
        <div className="flex flex-column">
          {olympiads && (
            <div>
              <p className="card-details">Olympiads Participated :</p>
              <div
                className="fieldsWrapper"
                style={{ width: "100%", display: "flex" }}
              >
                {olympiads &&
                  olympiads.map((field) => {
                    return <div className="fieldWrapper">{field}</div>;
                  })}
              </div>
            </div>
          )}
          {subjects && (
            <div>
              <p className="card-details">Preffered Subjects :</p>
              <div
                className="fieldsWrapper"
                style={{ width: "100%", display: "flex" }}
              >
                {subjects &&
                  subjects.map((field) => {
                    return <div className="fieldWrapper">{field}</div>;
                  })}
              </div>
            </div>
          )}
        </div>
      </div>
      {/* <hr style={{ margin: "4rem 0", opacity: "60%" }} /> */}
    </div>
  );
};

export default OtherDetails;
