import React from "react";
import CollegeIcon from "../../../../../../assets/collegeIcon.png";
import SchoolBoard from "../../../../../options/schoolBoard";

const EducationLevel = ({ institution, schoolBoard, currentClass }) => {
  return (
    <div className="flex">
      <img src={CollegeIcon} className="card-image" />
      <div className="flex flex-column">
        <h2 className="card-subtitle">{institution}</h2>
        <p
          style={{
            fontSize: "1.5rem",
            fontWeight: "bold",
            marginBottom: "1rem",
          }}
        >
          <span className="card-details">
            {SchoolBoard[JSON.parse(schoolBoard) - 1].label}
          </span>
        </p>
        <p className="card-details">Class {currentClass}</p>
      </div>
    </div>
  );
};

export default EducationLevel;
