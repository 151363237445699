import React, { useState, useEffect } from "react";
import EducationLevel from "./EducationLevel";

const EducationCard = ({
  collegename,
  collegeyear,
  collegedegree,
  gpa,
  specialization,
}) => {
  console.log("college degree is ", collegedegree);
  return (
    <div className="card">
      <h1 className="card-title">Education</h1>
      {collegename && (
        <div>
          <EducationLevel
            institution={collegename}
            degree={collegedegree}
            specialization={specialization}
            type="college"
            year={collegeyear}
            gpa={gpa}
          ></EducationLevel>
        </div>
      )}
    </div>
  );
};

export default EducationCard;
