import "./index.css";
import React, { useState, useEffect } from "react";
import Header from "../../Headers/HeaderForms";
import Footer from "../../Footer/Footer.js";
import Dropdown from "../../Usables/Dropdown/Dropdown.js";
import SearchDropdown from "../../Usables/SearchDropdown/index.js";
import FloatInputField from "../../Usables/FloatInputField/index.js";
import MultiSelectDropdown from "../../Usables/MultiSelectDropdown/index.js";
import { useLoader } from "../../../context/LoaderContext.js";
import { useNavigate } from "react-router-dom";
import BachelorExams from "../Bachelors/BachelorsExam";
import ResumePicker from "../../Usables/ResumePicker";
import RegionsModal from "../../Usables/RegionModal";
import useAuth from "../../../hooks/useAuth.js";
import axios from "../../../api/axios";
import useAxiosPrivate from "../../../hooks/useAxiosPrivate";
// data
import Specializations from "../../options/phdDegree.js";
import CollegeList from "../FormsData/Colleges.js";
import YearOfStudy from "../FormsData/Year.js";
import FutureOpportunityTypesList from "../FormsData/FutureFields.js";

const PhdForm = () => {
  // API VARIABLES

  const navigate = useNavigate();
  const { auth } = useAuth();
  const axiosPrivate = useAxiosPrivate();
  const SAVE_LOGIN_ROUTE = "/api/saveLoginRoute";
  const SAVE_CATEGORY = "/api/saveCategory";

  const { setIsLoading } = useLoader();

  // variables
  const [college, setCollege] = useState(null);
  const [collegeName, setCollegeName] = useState(null);
  const [year, setYear] = useState(null);
  const [yearRAG, setYearRAG] = useState(null);
  const [specialization, setSpecialization] = useState(null);
  const [specializationRAG, setSpecializationRAG] = useState(null);
  const [compensation, setCompensation] = useState(null);
  const [gpa, setGPA] = useState(null);
  const [futureOpportunityTypes, setFutureOpportunityTypes] = useState([]);

  const newExam = [
    {
      type: "text",
      id: 1,
      name: "",
      date: "",
      percentage: "",
      criteria: "100 %",
    },
  ];

  const [exams, setExams] = useState(newExam);

  const newRegion = [
    {
      id: 1,
      regionName: "Select Region",
      subRegions: [],
      check: false,
    },
  ];

  const [regions, setRegions] = useState(newRegion);

  const [fileName, setFileName] = useState("");

  const handleFile = (file) => {
    setFileName(file.name);
  };

  const handleFileRemove = () => {
    setFileName("");
  };

  const crossBtn = (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      x="0px"
      y="0px"
      width="30"
      viewBox="0 0 48 48"
    >
      <path
        fill="#f44336"
        d="M44,24c0,11.045-8.955,20-20,20S4,35.045,4,24S12.955,4,24,4S44,12.955,44,24z"
      ></path>
      <path
        fill="#fff"
        d="M29.656,15.516l2.828,2.828l-14.14,14.14l-2.828-2.828L29.656,15.516z"
      ></path>
      <path
        fill="#fff"
        d="M32.484,29.656l-2.828,2.828l-14.14-14.14l2.828-2.828L32.484,29.656z"
      ></path>
    </svg>
  );

  // Variable handlers
  const handleCollege = (value, label) => {
    setCollegeName(label);
    setCollege(value);
  };

  const handleYear = (e) => {
    setYear(e.value);
    // e.value is a number in form of string, and our database has string only
    let ind = parseInt(e.value) - 1;
    setYearRAG(YearOfStudy[ind].label);
  };

  const handleCompensation = (event) => {
    const value = event.target.value;
    setCompensation(value);
  };

  const handleGPA = (event) => {
    const value = event.target.value;
    setGPA(value);
  };

  const handleSpecialization = (value, label) => {
    setSpecializationRAG(label);
    setSpecialization(value);
  };

  const handleFutureOppsTypes = (options) => {
    setFutureOpportunityTypes(options);
  };

  const getLabelsAndValues = (arr) => {
    let labels = [];
    let values = [];
    for (let i = 0; i < arr.length; i++) {
      labels.push(arr[i].label);
      values.push(arr[i].value);
    }
    return { labels, values };
  };

  const { labels: futureOppsTypesLabels, values: futureOppsTypesValues } =
    getLabelsAndValues(futureOpportunityTypes);

  const convertRegions = (regions) => {
    let finalRegions = [];
    for (let i = 0; i < regions.length; i++) {
      let region = regions[i];
      let regionName = region.regionName;
      let finalSubRegions = [];
      let subRegions = region.subRegions;
      for (let j = 0; j < subRegions.length; j++) {
        finalSubRegions.push(subRegions[j].label);
      }
      const obj = {
        continent: regionName,
        countries: JSON.stringify(finalSubRegions),
      };
      finalRegions.push(obj);
    }
    return finalRegions;
  };

  const finalRegions = convertRegions(regions);

  const handleClick = async () => {
    try {
      setIsLoading(true);
      await axiosPrivate.post(
        SAVE_CATEGORY,
        {
          userID: auth.userID,
          category: "phd",
        },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${auth.accessToken}`,
          },
          withCredentials: true,
        }
      );
      const userDetail = await axios.get("/api/getuserdata", {
        params: {
          userID: auth.userID,
        },
      });
      const userData = userDetail.data;
      const data = {
        userID: auth.userID,
        college: college,
        year: year,
        specialization: specialization,
        compensation: compensation,
        gpa: gpa,
        exams: JSON.stringify(exams),
        interestedregions: JSON.stringify(finalRegions),
        futureOpportunityTypes: JSON.stringify(futureOppsTypesValues),
      };
      console.log("all data is", data);

      // Handle the response as needed
      const controller = new AbortController();
      const response1 = await axiosPrivate.post("/api/phd", data, {
        signal: controller.signal,
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${auth.accessToken}`,
        },
        withCredentials: true,
      });

      if (response1.ok) {
        const responseData = await response1.json();
        console.log("PHD details saved successfully:", responseData);
      } else {
        console.log("The current data state is :", response1.statusText);
      }
      await axiosPrivate.post(
        SAVE_LOGIN_ROUTE,
        {
          userID: auth.userID,
          loginroute: "profile",
        },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${auth.accessToken}`,
          },
          withCredentials: true,
        }
      );
      fetchOpportunities(userData);
      navigate("/profile");
      controller.abort();
    } catch (err) {
      console.log(err);
    } finally {
      setIsLoading(false);
    }
  };

  const fetchOpportunities = async (userData) => {
    const gender = userData.gender;
    const country = userData.country;
    try {
      const response = await axios.post(
        "https://opgururag.azurewebsites.net/search",
        {
          query: `Find ten different opportunities that best match the profile of a user who is currently a PHD student. The student is a ${gender}, and is currently doing PHD at ${collegeName} with specialization in ${specializationRAG} with a current GPA ( out of 10 ) of ${gpa}. The student is currently in their ${yearRAG}. The student is from ${country} and their fields of interest for future study include ${JSON.stringify(
            futureOppsTypesLabels
          )}. The student is interested in opportunities in the following regions: ${JSON.stringify(
            regions
          )}. The student have appeared for the following proficiency exams with scores as : ${JSON.stringify(
            exams
          )}. Please find opportunities including internships, scholarships, fellowships, exchange programs, and conferences. Provide maximum information for each opportunity, including the title, application deadline, eligibility criteria (such as age limits, GPA requirements, current degree pursuing, eligible regions, citizenship, work experience), location/country, description, and type of opportunity. Return the results in a structured format for easy parsing and integration.`,
          userID: auth.userID,
        }
      );
      console.log("response data after opgururag is =>", response.data);
      const finalData = response.data;
      console.log("finalData is ", finalData);
    } catch (error) {
      console.error("Error fetching Opportunities:", error);
    }
  };

  // Printing to check
  const dataDB = {
    userID: JSON.stringify(auth.userID),
    college: JSON.stringify(college),
    year: JSON.stringify(year),
    specialization: JSON.stringify(specialization),
    compensation: JSON.stringify(compensation),
    gpa: JSON.stringify(gpa),
    exams: JSON.stringify(exams), // Stringify the array
    interestedregions: JSON.stringify(finalRegions),
    futureOpportunityTypes: JSON.stringify(futureOppsTypesValues),
  };
  const dataRag = {
    userID: JSON.stringify(auth.userID),
    college: JSON.stringify(collegeName),
    year: JSON.stringify(yearRAG),
    specialization: JSON.stringify(specializationRAG),
    compensation: JSON.stringify(compensation),
    gpa: JSON.stringify(gpa),
    exams: JSON.stringify(exams), // Stringify the array
    interestedregions: JSON.stringify(finalRegions),
    futureOpportunityTypes: JSON.stringify(futureOppsTypesLabels),
  };

  useEffect(() => {
    console.log("data for db is ", dataDB);
    console.log("data for RAG is ", dataRag);
  }, [dataDB, dataRag]);

  return (
    <div className="userFormWrapper">
      <Header></Header>
      <div className="studentFormWrapper">
        <div className="formHeading">Registration Form for PHD Students</div>
        <div className="formMessage">
          Enter all Your personal details mention below
        </div>
        <form style={{ width: "100%", marginBottom: "1rem" }}>
          <div className="inputForm">
            <SearchDropdown
              options={CollegeList}
              onSelect={handleCollege}
              placeholderInput="Search your College"
              placeholderDropdown="College Name"
            />
            <br />
            <Dropdown
              name="currentYear"
              value={year}
              options={YearOfStudy}
              placeHolder="Current Year of Study"
              onChange={handleYear}
            ></Dropdown>
            <br />
            <SearchDropdown
              options={Specializations}
              onSelect={handleSpecialization}
              placeholderInput="Search your specialization"
              placeholderDropdown="Specialization"
            />
            <FloatInputField
              name="expectedCompensation"
              placeHolder="Expected Compensation (INR Monthly)"
              handleInput={handleCompensation}
              inputVal={compensation}
            ></FloatInputField>
            <FloatInputField
              name="gpa"
              placeHolder="Current GPA ( On a scale of 10 )"
              handleInput={handleGPA}
              inputVal={gpa}
            ></FloatInputField>
            <MultiSelectDropdown
              options={FutureOpportunityTypesList}
              onSelect={handleFutureOppsTypes}
              placeholderDropdown="Opportunity types interested in"
              placeholderInput="Search for interested fields"
              showSearchBar={true}
              showSelectAll={false}
            ></MultiSelectDropdown>
            <br />
            <BachelorExams exams={exams} setExams={setExams}></BachelorExams>
          </div>
        </form>
        <div style={{ width: "100%" }}>
          <RegionsModal
            regions={regions}
            setRegions={setRegions}
          ></RegionsModal>
        </div>
        <div
          className="bachelorFormInput"
          style={{ alignSelf: "flex-start", margin: "2rem" }}
        >
          <ResumePicker
            fileName={fileName}
            handleFile={handleFile}
            handleFileRemove={handleFileRemove}
          ></ResumePicker>
        </div>
        {fileName !== "" ? (
          <div className="inputForm uploadedFile">
            <div
              className="inputForm"
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                width: "92%",
              }}
            >
              <div>Uploaded file : {fileName}</div>
            </div>
            <button className="removeBtn" onClick={handleFileRemove}>
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                {crossBtn}
              </div>
            </button>
          </div>
        ) : (
          <div></div>
        )}
        <button
          onClick={handleClick}
          style={{ width: "50%" }}
          className="yellowBtn"
        >
          Save and Continue
        </button>
      </div>
      <Footer></Footer>
    </div>
  );
};

export default PhdForm;
