import Students from "../../../assets/Students.png";
import Employees from "../../../assets/Employees.png";
import Parents from "../../../assets/Parents.png";
import Institutions from "../../../assets/Institutions.png";

const CategoryInfo = [
  {
    id: 1,
    url: Students,
    type: "Students",
    Info: "Students gain access to a wider array of opportunities for a brighter future.",
  },
  {
    id: 2,
    url: Employees,
    type: "Employees",
    Info: "Freshers and seasoned professionals can come together in a nurturing environment to grow, share, and thrive.",
  },
  {
    id: 3,
    url: Parents,
    type: "Parents",
    Info: "Parents in underserved areas gain insights into valuable resources to expand their children’s knowledge and dreams",
  },
  {
    id: 4,
    url: Institutions,
    type: "Institutions",
    Info: "OpGuru empowers schools in remote areas to connect students with global opportunities.",
  },
];

export default CategoryInfo;
