import React, { useState, useEffect } from "react";
import EducationLevel from "./EducationLevel";

const EducationCard = ({
  schoolName,
  schoolBoard,
  currentClass,
  tenthschoolname,
  tenthboard,
  tenthpercentage,
}) => {
  if (currentClass === "11") currentClass = "11th";
  else if (currentClass === "12") currentClass = "12th";
  return (
    <div className="card">
      <h1 className="card-title">Education</h1>
      {schoolName && (
        <div>
          <EducationLevel
            institution={schoolName}
            schoolBoard={schoolBoard}
            currentClass={currentClass}
          ></EducationLevel>
        </div>
      )}
      <hr style={{ margin: "4rem 0", opacity: "60%" }} />
      {schoolName && (
        <div>
          <EducationLevel
            institution={tenthschoolname}
            schoolBoard={tenthboard}
            currentClass="10th"
            percentage={tenthpercentage}
          ></EducationLevel>
        </div>
      )}
    </div>
  );
};

export default EducationCard;
