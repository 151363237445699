import React, { useState } from "react";
import CrossBtn from "../../../assets/cross-button.svg";
import MyButton from "../../Usables/Buttons/Button";
import MyInputField from "../../Usables/InputField/Input";
import "./index.css";

function EditSkillsModal({ isOpen, onClose, initialSkills, onSave }) {
  const [selectedSkills, setSelectedSkills] = useState([...initialSkills]);
  const [filterText, setFilterText] = useState("");

  const toggleSkill = (skill) => {
    if (selectedSkills.includes(skill)) {
      setSelectedSkills(
        selectedSkills.filter((selected) => selected !== skill)
      );
    } else {
      setSelectedSkills([...selectedSkills, skill]);
    }
  };

  const isSkillSelected = (skill) => selectedSkills.includes(skill);

  const handleSaveClick = () => {
    onSave(selectedSkills);
    onClose();
  };

  const filteredSkills = [
    "HTML",
    "CSS",
    "Communication",
    "Financial",
    "Figma",
    "Adobe-XD",
    "Photoshop",
    "Illustrator",
    "Indesign",
    "Graphic Design",
    "Web Design",
    "UI/UX Design",
    "Docker",
    "Canva",
  ].filter((skill) => skill.toLowerCase().includes(filterText.toLowerCase()));

  return (
    <div className={`modal ${isOpen ? "open" : ""}`}>
      <div className="modal-content-profile">
        <button className="close-button" onClick={onClose}>
          <img className="crossBtnImg" src={CrossBtn} alt="/" />
        </button>
        <h4 className="bold-text">Skills</h4>
        <MyInputField
          placeholder="Filter skills..."
          widthWrapper="70%"
          value={filterText}
          onChange={(e) => setFilterText(e.target.value)}
        ></MyInputField>
        <div className="skillsWrapper">
          {filteredSkills.map((skill) => (
            <div
              key={skill}
              onClick={() => toggleSkill(skill)}
              className={`skill-option ${
                isSkillSelected(skill) ? "selected" : ""
              }`}
            >
              {skill}
            </div>
          ))}
        </div>
        <button className="modalSaveButton" onClick={handleSaveClick}>
          Save & Continue
        </button>
      </div>
    </div>
  );
}

export default EditSkillsModal;
