import React, { useState, useEffect } from "react";
import EducationCard from "./EducationCard";
import InterestedFieldsCard from "./InterestedFields/InterestedFields";
import Opportunities from "../../../options/OpportunitiesTypes8th.js";
import OtherDetails from "./OtherDetails/index.jsx";
import Olympiads from "../../../options/Olympiads.js";
import Streams from "../../../options/Streams.js";
import Subjects from "../../../options/InterestedSubjects8th.js";
import axios from "../../../../api/axios";

const NineTenthProfile = ({ userID, category }) => {
  const GET_NINETENTHUSER_ROUTE = "/api/getNineTenthUser";
  const [schoolName, setSchoolName] = useState(null);
  const [schoolBoard, setSchoolBoard] = useState(null);
  const [currentClass, setCurrentClass] = useState(null);
  const [futurestream, setFutureStream] = useState(null);
  const [olympiads, setOlympiads] = useState(null);
  const [interestedFields, setInterestedFields] = useState(null);
  const [prefferedSubjects, setPrefferedSubjects] = useState(null);
  useEffect(() => {
    const getData = async () => {
      const userDetails = await axios.get(GET_NINETENTHUSER_ROUTE, {
        params: {
          userID: userID,
        },
      });
      setSchoolName(userDetails?.data?.schoolname);
      setSchoolBoard(userDetails?.data?.schoolBoard);
      setCurrentClass(userDetails?.data?.currentclass);
      setFutureStream(
        Streams[JSON.parse(userDetails?.data?.futurestream) - 1].label
      );
      let arr1 = [];
      let FutureoppsTypes = JSON.parse(userDetails?.data?.opportunities);
      for (let i = 0; i < FutureoppsTypes.length; i++) {
        const Index = parseInt(FutureoppsTypes[i], 10) - 1;
        if (Opportunities[Index]) {
          arr1.push(Opportunities[Index].label);
        }
      }
      setInterestedFields(arr1);
      let arr2 = [];
      let OlympiadsPartipated = JSON.parse(userDetails?.data?.olympiads);
      for (let i = 0; i < OlympiadsPartipated.length; i++) {
        const Index = parseInt(OlympiadsPartipated[i], 10) - 1;
        if (Olympiads[Index]) {
          arr2.push(Olympiads[Index].label);
        }
      }
      setOlympiads(arr2);
      let arr3 = [];
      let PrefferedSubjects = JSON.parse(userDetails?.data?.subjects);
      for (let i = 0; i < PrefferedSubjects.length; i++) {
        const Index = parseInt(PrefferedSubjects[i], 10) - 1;
        if (Subjects[Index]) {
          arr3.push(Subjects[Index].label);
        }
      }
      setPrefferedSubjects(arr3);
    };
    getData();
  }, [userID, category]);
  return (
    <div>
      <EducationCard
        schoolName={schoolName}
        schoolBoard={schoolBoard}
        currentClass={currentClass}
      ></EducationCard>
      <InterestedFieldsCard
        interestedFields={interestedFields}
        futurestream={futurestream}
      ></InterestedFieldsCard>
      <OtherDetails
        olympiads={olympiads}
        subjects={prefferedSubjects}
      ></OtherDetails>
    </div>
  );
};

export default NineTenthProfile;
