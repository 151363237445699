import React, { useState, useEffect } from "react";
import Message from "../../LeftBanner";
import Lock from "../../../assets/Lock.png";
import { Form, Button } from "react-bootstrap";
import "react-phone-number-input/style.css";
import PhoneInput from "react-phone-number-input";
import { useUserAuth } from "../../../context/UserAuthContext.js";
import { useNavigate, Link } from "react-router-dom";
import axios from "axios";

const ForgotPassEmail = () => {
  const navigate = useNavigate();
  const [error, setError] = useState("");

  const PhnoForgotHandle = () => {
    navigate("/forgotpassword/phno");
  };
  const [email, setEmail] = useState("");
  const [success, setSuccess] = useState(false);

  useEffect(() => {
    console.log(success);
    setSuccess(success);
  }, [success]);

  const getLink = async (e) => {
    e.preventDefault();
    const useremail = {
      email: email,
    };
    // email ko ek json object mein daal kar bheja bhai
    setError("");
    if (email) {
      try {
        const response = await axios.post("/api/sendpasswordlink", useremail);
        const data = JSON.stringify(response?.data);
        console.log("data is ", JSON.stringify(response?.data));
        if (data) {
          setEmail("");
          setSuccess(true);
        }
      } catch (err) {
        console.log(err);
      }
    }
  };

  return (
    <div className="forgotPassWrapper">
      <div className="messageLogin">
        <Message></Message>
      </div>
      <div className="forgotPassForm">
        <div className="midWrapForgotPass">
          <div className="Lock">
            <img src={Lock} alt="/" className="OtpImg" />
          </div>
          <div className="pageName">Forgot Password?</div>
          <div className="dontWorryMsg">
            Don’t worry! it happens. Please enter the Email associated with your
            account we’ll send you an OTP to verity it’s you.
          </div>
          <form onSubmit={getLink} id="forgotPassForm">
            <div className="emailWrap">
              <input
                type="text"
                id="email"
                placeholder="Email"
                // ref={nameRef}
                autoComplete="off"
                onChange={(e) => setEmail(e.target.value)}
                value={email}
                className="form-control"
              />
            </div>
            <div
              className="successMessage"
              style={{ display: success ? "block" : "none" }}
            >
              The password reset link has been sent{" "}
            </div>
          </form>
          <button
            type="submit"
            className="yellowBtn"
            form="forgotPassForm"
            value="Submit"
          >
            Next
          </button>
          <div className="methodChoice">Or</div>
          <div className="userIDForgot">
            <Button className="whiteBtn" onClick={PhnoForgotHandle}>
              Forgot Via Phone Number
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ForgotPassEmail;
