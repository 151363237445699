import React, { useState, useEffect } from "react";
import "./index.css";
import CrossBtn from "../../../assets/cross-button.svg";
import MyInputField from "../../Usables/InputField/Input";

function EditProfileModal({ isOpen, onClose, initialData, onSave }) {
  const [editedData, setEditedData] = useState({ ...initialData });

  const handleSaveClick = () => {
    onSave(editedData);
    onClose();
  };

  const handleClickOutside = (event) => {
    if (event.target.className.includes("modal")) {
      onSave(editedData);
      onClose();
    }
  };

  useEffect(() => {
    if (isOpen) {
      window.addEventListener("click", handleClickOutside);
    } else {
      window.removeEventListener("click", handleClickOutside);
    }

    return () => window.removeEventListener("click", handleClickOutside);
  }, [isOpen]);

  return (
    <div className={`modal ${isOpen ? "open" : ""}`}>
      <div className="modal-content">
        <button className="close-button" onClick={onClose}>
          <img className="crossBtnImg" src={CrossBtn} alt="/" />
        </button>
        <h2 className="bold-text">Personal Details</h2>
        <div className="inputEditProfile">
          <MyInputField
            label="Full Name"
            value={editedData.fullName}
            onChange={(e) =>
              setEditedData({ ...editedData, fullName: e.target.value })
            }
          ></MyInputField>
        </div>
        <div className="inputEditProfile">
          <MyInputField
            label="Contact Number"
            value={editedData.contactNumber}
            onChange={(e) =>
              setEditedData({ ...editedData, contactNumber: e.target.value })
            }
          ></MyInputField>
        </div>
        <div className="inputEditProfile">
          <MyInputField
            label="User ID"
            value={editedData.userID}
            onChange={(e) =>
              setEditedData({ ...editedData, userID: e.target.value })
            }
          ></MyInputField>
        </div>
        <div className="inputEditProfile">
          <MyInputField
            label="Gender :"
            value={editedData.gender}
            onChange={(e) =>
              setEditedData({ ...editedData, gender: e.target.value })
            }
          ></MyInputField>
        </div>
        <div className="inputEditProfile" style={{ paddingBottom: "1.6rem" }}>
          <MyInputField
            label="Address :"
            value={editedData.address}
            onChange={(e) =>
              setEditedData({ ...editedData, address: e.target.value })
            }
          ></MyInputField>
        </div>
        <button className="modalSaveButton" onClick={handleSaveClick}>
          Save & Continue
        </button>
      </div>
    </div>
  );
}
export default EditProfileModal;
