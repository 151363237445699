import React from "react";
import "./Footer.css";
import mailIcon from "../../assets/mailIcon.png";
import phoneIcon from "../../assets/phoneIcon.png";
import OpguruLogo from "../../assets/opgurulogowhite.png";
import { FaLinkedin } from "react-icons/fa";
import { FaYoutube } from "react-icons/fa";

const Footer = () => {
  const SocialHandles = {
    linkedin: "https://www.linkedin.com/company/opguru/",
    youtube: "https://www.youtube.com/@OpGuru__",
  };
  return (
    <div className="footerWrapper">
      <div className="footerMain">
        <div className="brochureSection">
          <div className="leadInfo">
            <div className="footerLogoCon">
              <img src={OpguruLogo} alt="/" className="footerLogoImg" />
            </div>
            <div className="footerDescription">
              A one stop platform for all kinds of opportunities
            </div>
            <button className="brochureButton">Download Brochure</button>
          </div>
        </div>

        {/* <div className="navSection">
          <div className="footerNavLink">Home</div>
          <div className="footerNavLink">About Us</div>
          <div className="footerNavLink">Our Collaborations</div>
          <div className="footerNavLink">Blogs</div>
        </div> */}

        <div className="contactSection">
          <div className="footerTitle">Reach out to us</div>
          <div className="contactInfo">
            <div className="contactMode">
              <img src={mailIcon} className="contactIcon" />
              <div className="contactInfo">contact@opguruai.com</div>
            </div>
            <div className="contactMode">
              <img src={phoneIcon} className="contactIcon" />
              <div className="contactInfo">+91-9996752934</div>
            </div>
            <div className="opguruHandles">
              <div className="opguruHandle">
                <a
                  href={SocialHandles.linkedin}
                  target="_blank"
                  rel="noopener noreferrer"
                  className="icon-link"
                >
                  <FaLinkedin size={40} style={{ color: "#0A66C2" }} />
                </a>
              </div>
              <div className="opguruHandle">
                <a
                  href={SocialHandles.youtube}
                  target="_blank"
                  rel="noopener noreferrer"
                  className="icon-link"
                >
                  <FaYoutube size={40} style={{ color: "#FF0000" }} />
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="footerBottom">
        <hr className="separator" />
        <div className="copyRight">© Copyright 2024, All Rights Reserved</div>
      </div>
    </div>
  );
};

export default Footer;
