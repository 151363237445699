import React, { useState, useEffect } from "react";
import EducationCard from "./EducationCard";
import InterestedFieldsCard from "./InterestedFields/InterestedFields";
import OtherDetails from "./OtherDetails/index.jsx";
import Olympiads from "../../../userForms/FormsData/Olympiads.js";
import SchoolBoard from "../../../options/schoolBoard.js";
import CollegeList from "../../../userForms/FormsData/Colleges.js";
import FutureFields from "../../../userForms/FormsData/FutureFields.js";
import axios from "../../../../api/axios";

const BachelorsProfile = ({ userID, category }) => {
  const GET_BACHELORS_ROUTE = "/api/getBachelorsUser";
  const [collegeName, setCollegeName] = useState(null);
  const [collegeYear, setCollegeYear] = useState(null);
  const [twelthSchoolName, setTwelthSchoolName] = useState(null);
  const [tenthSchoolName, setTenthSchoolName] = useState(null);
  const [tenthBoard, setTenthSchoolBoard] = useState(null);
  const [twelthBoard, setTwelthSchoolBoard] = useState(null);
  const [tenthPercentage, setTenthPercentage] = useState(null);
  const [twelthPercentage, setTwelthPercentage] = useState(null);
  const [prevOlympiads, setPrevOlympiads] = useState(null);
  const [futureOlympiads, setFutureOlympiads] = useState(null);
  const [interestedFields, setInterestedFields] = useState(null);

  useEffect(() => {
    const getData = async () => {
      const userDetails = await axios.get(GET_BACHELORS_ROUTE, {
        params: {
          userID: userID,
        },
      });
      console.log("User details is ", userDetails.data);
      setCollegeName(
        CollegeList[JSON.parse(userDetails?.data?.collegename) - 1].label
      );
      setCollegeYear(userDetails?.data?.collegeyear);
      setTenthSchoolName(userDetails?.data?.tenthschoolname);
      setTenthSchoolBoard(
        SchoolBoard[JSON.parse(userDetails?.data?.tenthBoard) - 1].label
      );
      setTenthPercentage(userDetails?.data?.tenthPercentage);
      setTwelthSchoolName(userDetails?.data?.twelthschoolname);
      setTwelthSchoolBoard(
        SchoolBoard[JSON.parse(userDetails?.data?.twelthBoard) - 1].label
      );
      setTwelthPercentage(userDetails?.data?.twelthPercentage);

      let arr1 = [];
      let FutureFieldsArr = JSON.parse(userDetails?.data?.futurefields);
      for (let i = 0; i < FutureFieldsArr.length; i++) {
        const Index = parseInt(FutureFieldsArr[i], 10) - 1;
        if (FutureFields[Index]) {
          arr1.push(FutureFields[Index].label);
        }
      }
      setInterestedFields(arr1);
      let arr2 = [];
      console.log("OLympiads is ", Olympiads);
      let PrevOlympiads = JSON.parse(userDetails?.data?.olympiadparticipated);
      console.log("lodu array is ", PrevOlympiads);
      for (let i = 0; i < PrevOlympiads.length; i++) {
        const Index = parseInt(PrevOlympiads[i], 10) - 1;
        console.log("Index is ", Index);
        console.log("Olympiad is ", Olympiads[Index].label);
        if (Olympiads[Index]) {
          arr2.push(Olympiads[Index].label);
        }
      }
      console.log("Future olympiads are ", arr2);
      setPrevOlympiads(arr2);
    };
    getData();
  }, [userID, category]);

  return (
    <div>
      <EducationCard
        collegename={collegeName}
        collegeyear={collegeYear}
        tenthschoolname={tenthSchoolName}
        tenthboard={tenthBoard}
        twelthschoolname={twelthSchoolName}
        twelthboard={twelthBoard}
        tenthpercentage={tenthPercentage}
        twelthpercentage={twelthPercentage}
      ></EducationCard>
      <InterestedFieldsCard
        interestedFields={interestedFields}
      ></InterestedFieldsCard>
      <OtherDetails
        prevOlympiads={prevOlympiads}
        futureOlympiads={futureOlympiads}
      ></OtherDetails>
    </div>
  );
};

export default BachelorsProfile;
