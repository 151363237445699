import React from "react";
import "./index.css";
import VisionImg from "../../../assets/VisionImg.png";

const Vision = () => {
  return (
    <div className="visionWrapper">
      <div className="mvImgWrapper">
        <img className="mvImg" src={VisionImg} alt="/" />
      </div>
      <div className="homeHeading1">
        Our{" "}
        <span className="homeHeadingSpan" style={{ color: "#DFA114" }}>
          Vision
        </span>
      </div>
      <div className="missionInfo">
        At OpGuru, our vision is to empower individuals in rural and underserved
        communities to realize their fullest potential and serve as a guiding
        light for their educational aspirations. We aim for a world where every
        student's journey is marked by clarity, empowerment, and fulfillment.
        Our commitment extends beyond mere guidance; we are dedicated to turning
        the dreams of students in these communities into reality. With
        compassion and focused support, we illuminate the path to educational
        excellence, inspiring future generations to achieve their aspirations
        and create a brighter future for all.
      </div>
    </div>
  );
};

export default Vision;
