import React from "react";
import "./index.css";
import AboutUsImg from "../../../assets/aboutUsImg.png";
import LazyImage from "../../Usables/LazyImage";
import CircleImage from "../../../assets/Circle Shape.png";
import CircleImageRight from "../../../assets/Circle_Shape_Right.png";

const AboutUsInfo = () => {
  return (
    <div className="aboutusInfoWrapper">
      <img src={CircleImage} alt="/" className="bgCircleLeft" />
      <img src={CircleImageRight} alt="/" className="bgCircleRight" />
      <div className="aboutUsHeading">About Us</div>
      <div className="aboutUsContent">
        <LazyImage
          src={AboutUsImg}
          alt="Benifits Image"
          style={{ width: "70%", paddingLeft: "10rem" }} // Optional: Custom styles for the image
          containerStyle={{ width: "50%" }} // Optional: Custom styles for the container
          mobileContainerStyle={{
            width: "80%",
            margin: "auto",
            paddingBottom: "4rem",
          }}
        />
        <div className="aboutUsRight">
          <div className="aboutUsInfo">
            OpGuru is a transformative platform dedicated to empowering students
            and professionals in underserved communities. We provide
            personalized mentorship, tailored guidance, and access to global
            opportunities, enabling individuals from smaller cities and rural
            areas to navigate their career paths with confidence. Our mission is
            to bridge opportunity gaps through AI-driven resources, connecting
            users with mentors, jobs, and professional development. By
            collaborating with educational institutions, we broaden horizons and
            unlock potential, ensuring that every individual can thrive and
            contribute meaningfully to society. Join us in shaping a brighter
            future for all.
          </div>
        </div>
      </div>
    </div>
  );
};

export default AboutUsInfo;
