import React, { useState, useEffect } from "react";
import EducationCard from "./EducationCard";
import InterestedFieldsCard from "./InterestedFields/InterestedFields";
import FutureDegree from "../../../options/FutureDegree.js";
import OtherDetails from "./OtherDetails/index.jsx";
import Olympiads from "../../../options/Olympiads.js";
import axios from "../../../../api/axios";

const ElevenTwelthProfile = ({ userID, category }) => {
  const GET_ELEVENTWELTHUSER_ROUTE = "/api/getElevenTwelthUser";
  const [schoolName, setSchoolName] = useState(null);
  const [schoolBoard, setSchoolBoard] = useState(null);
  const [currentClass, setCurrentClass] = useState(null);
  const [tenthSchoolName, setTenthSchoolName] = useState(null);
  const [tenthBoard, setTenthSchoolBoard] = useState(null);
  const [tenthPercentage, setTenthPercentage] = useState(null);
  const [futuredegree, setFutureDegree] = useState(null);
  const [prevOlympiads, setPrevOlympiads] = useState(null);
  const [futureOlympiads, setFutureOlympiads] = useState(null);
  const [interestedFields, setInterestedFields] = useState(null);
  useEffect(() => {
    const getData = async () => {
      const userDetails = await axios.get(GET_ELEVENTWELTHUSER_ROUTE, {
        params: {
          userID: userID,
        },
      });
      console.log("User details is ", userDetails.data);
      setSchoolName(userDetails?.data?.schoolname);
      setSchoolBoard(userDetails?.data?.schoolBoard);
      setCurrentClass(userDetails?.data?.currentclass);
      setTenthSchoolBoard(userDetails?.data?.class10thboard);
      setTenthSchoolName(userDetails?.data?.tenthschoolname);
      setTenthPercentage(userDetails?.data?.class10percent);
      setInterestedFields(JSON.parse(userDetails?.data?.futureolympiadtypes));

      setFutureDegree(
        FutureDegree[JSON.parse(userDetails?.data.futuredegree) - 1].label
      );
      let arr1 = [];
      let PrevOlympiads = JSON.parse(userDetails?.data?.olympiadparticipated);
      for (let i = 0; i < PrevOlympiads.length; i++) {
        const Index = parseInt(PrevOlympiads[i], 10) - 1;
        if (Olympiads[Index]) {
          arr1.push(Olympiads[Index].label);
        }
      }
      setPrevOlympiads(arr1);
      let arr2 = [];
      let FutureOlympiads = JSON.parse(userDetails?.data?.olympiadinterested);
      for (let i = 0; i < FutureOlympiads.length; i++) {
        const Index = parseInt(FutureOlympiads[i], 10) - 1;
        if (Olympiads[Index]) {
          arr2.push(Olympiads[Index].label);
        }
      }
      setFutureOlympiads(arr2);
    };
    getData();
  }, [userID, category]);

  useEffect(() => {
    console.log("Prev olympiads is ", prevOlympiads);
    console.log("Future olympiads is ", futureOlympiads);
  }, [prevOlympiads, futureOlympiads]);

  return (
    <div>
      <EducationCard
        schoolName={schoolName}
        schoolBoard={schoolBoard}
        currentClass={currentClass}
        tenthschoolname={tenthSchoolName}
        tenthboard={tenthBoard}
        tenthpercentage={tenthPercentage}
      ></EducationCard>
      <InterestedFieldsCard
        interestedFields={interestedFields}
        futuredegree={futuredegree}
      ></InterestedFieldsCard>
      <OtherDetails
        prevOlympiads={prevOlympiads}
        futureOlympiads={futureOlympiads}
      ></OtherDetails>
    </div>
  );
};

export default ElevenTwelthProfile;
